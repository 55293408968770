import * as commonSchema from 'common/src/schema'

export const getCodeSnippet = (data: Partial<commonSchema.SimplifiedJobInput>, urlBase: string, key: string): string => {
    const isLocalhost = (urlBase.indexOf('localhost') > -1)
    // console.log(JSON.stringify(data, null, 2))
    return `curl ${urlBase}/api/v0/job \\
${(isLocalhost || true) ? '  -k \\\n' : ''}  --request POST \\
  -u ${key}:a \\
  --header 'Content-Type: application/json' \\
  -d '${JSON.stringify(data, null, 2)}'`
}

