/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import hljs from 'highlight.js'
// importb bash from 'highlight.js/lib/languages/bash'
// import shell from 'highlight.js/lib/languages/shell'
// hljs.registerLanguage('shell', shell)
// hljs.registerLanguage('shell', bash)
import 'highlight.js/styles/github.css'
import '@thisbeyond/solid-select/style.css'

// import 'prismjs/themes/prism.css'
import Prism from 'prismjs'
window.Prism = Prism
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.min.css'

// hljs.registerLanguage('json', bashLang)
import { Job } from 'api/src/domain/domain'
import clone from 'clone-deep'
import { unifyZodErrors } from 'common'
import { computeProgress, computeState } from 'common'
import { constraints, Data, enforceConstraintsNew, getLimits, minBitrate } from 'common/src/constraints'
import { defaults } from 'common/src/defaults'
import * as commonSchema from 'common/src/schema'
// import hljs from 'highlight.js/lib/core'
import { Highlight, Language } from 'solid-highlight'
import { Accessor, batch, createEffect, createMemo, createSignal, For, JSX, Show, Signal } from 'solid-js'
import { createMutable } from 'solid-js/store'

import { copyToClipboard, JOB_CONFIG_CACHE_KEY, MainState } from '../common'
import { Inputs, Row } from './Row'
import { Select } from './Select'
import { main } from './style'
// hljs.configure({ lang })


const presets: { name: string, profiles: { width: number, height: number, h264Bitrate:number | null, hevcBitrate: number }[] }[] = [
    { 'name': 'Passthrough', profiles: [{ 'width': 0, 'height': 0, h264Bitrate: 600, hevcBitrate: 400  }] },
    { 'name': 'PC1', profiles: [{ 'width': 416, 'height': 232, h264Bitrate: 600, hevcBitrate: 400  }] },
    { 'name': 'PC2', profiles: [{ 'width': 640, 'height': 360, h264Bitrate: 800, hevcBitrate: 500  }] },
    { 'name': 'PC3', profiles: [{ 'width': 640, 'height': 480, h264Bitrate: 1000, hevcBitrate: 750  }] },
    { 'name': 'SD1', profiles: [{ 'width': 856, 'height': 480, h264Bitrate: 2000, hevcBitrate: 1500 }] },
    { 'name': 'SD2', profiles: [{ 'width': 960, 'height': 544, h264Bitrate: 2200, hevcBitrate: 1700 }] },
    { 'name': 'HD1', profiles: [{ 'width': 1280, 'height': 720, h264Bitrate: 3500, hevcBitrate: 2500 }] },
    { 'name': 'HD2', profiles: [{ 'width': 1920, 'height': 1080, h264Bitrate: 5500, hevcBitrate: 4000 }] },
    { 'name': '2K',  profiles: [{ 'width': 2560, 'height': 1440, h264Bitrate: 8500, hevcBitrate: 8500   }] },
    { 'name': '4K',  profiles: [{ 'width': 3840, 'height': 2160, h264Bitrate: 18000, hevcBitrate: 18000  }] },
    { 'name'  : 'SD Ladder', profiles: [
        { 'width': 416, height: 232, h264Bitrate: 600, hevcBitrate: 400 },
        { 'width': 640, height: 360, h264Bitrate: 800, hevcBitrate: 500 },
        { 'width': 856, height: 480, h264Bitrate: 2000, hevcBitrate: 1500 },
    ] },
    { 'name'  : 'HD Ladder', profiles: [
        { width: 416, height: 232, h264Bitrate: 600, hevcBitrate: 400 },
        { width: 640, height: 360, h264Bitrate: 800, hevcBitrate: 500 },
        // { width: 856, height: 480, h264Bitrate: 2000, hevcBitrate: 1500 },
        { width: 960, height: 544, h264Bitrate: 2200, hevcBitrate: 1700 },
        { width: 1280, height: 720, h264Bitrate: 3500, hevcBitrate: 2500 },
        { width: 1920, height: 1080, h264Bitrate: 5500, hevcBitrate: 4000 },
    ] },
    { 'name'  : '2K Ladder', profiles: [
        { width: 416, height: 232, h264Bitrate: 600, hevcBitrate: 400 },
        { width: 640, height: 360, h264Bitrate: 800, hevcBitrate: 500 },
        // { width: 856, height: 480, h264Bitrate: 2000, hevcBitrate: 1500 },
        { width: 960, height: 544, h264Bitrate: 2200, hevcBitrate: 1700 },
        // { width: 1280, height: 720, h264Bitrate: 3500, hevcBitrate: 2500 },
        { width: 1920, height: 1080, h264Bitrate: 5500, hevcBitrate: 4000 },
        { width: 2560, height: 1440, h264Bitrate: 8500, hevcBitrate: 8500 },
    ] },
    { 'name'  : '4K Ladder', profiles: [
        { width: 416, height: 232,  h264Bitrate: 600, hevcBitrate: 400 },
        { width: 640, height: 360, h264Bitrate: 800, hevcBitrate: 500 },
        // { width: 856, height: 480, h264Bitrate: 2000, hevcBitrate: 1500 },
        { width: 960, height: 544, h264Bitrate: 2200, hevcBitrate: 1700 },
        // { width: 1280, height: 720, h264Bitrate: 3500, hevcBitrate: 2500 },
        { width: 1920, height: 1080, h264Bitrate: 5500, hevcBitrate: 4000 },
        // { width: 2560, height: 1440, h264Bitrate: 8500, hevcBitrate: 8500 },
        { width: 3840, height: 2160, h264Bitrate: 18000, hevcBitrate: 18000 },
    ] },
]
// type Data = PartialExcept<commonSchema.SimplifiedJobInput, 'videoProfiles' | 'audioProfiles' | 'cloud_credentials'>
const MAX_NUMBER_OF_VIDEO_PROFILES = 10
const MAX_NUMBER_OF_AUDIO_PROFILES = 9

const SortZA = () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" enable-background="new 0 0 32 32">
    <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="23" y1="26.1" x2="23" y2="5"/>
    <polyline fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" points="18.7,21.8 23,26.1 27.3,21.8 "/>
    <polyline fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" points="12,27 12,26 9.1,18 8.9,18 6,26 6,27 "/>
    <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="6" y1="24" x2="12" y2="24"/>
    <polyline fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" points="5,6 12,6 12,7 6,13 6,14 13,14 "/>
</svg>
const SortAZ = () => <svg version="1.1" id="Layer_1" viewBox="0 0 32 32" enable-background="new 0 0 32 32">
    <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="23" y1="26.1" x2="23" y2="5"/>
    <polyline fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" points="18.7,21.8 23,26.1 27.3,21.8 "/>
    <polyline fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" points="12,15 12,14 9.1,6 8.9,6 6,14 6,15 "/>
    <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="6" y1="12" x2="12" y2="12"/>
    <polyline fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" points="5,18 12,18 12,19 6,25 6,26 13,26 "/>
</svg>

const applyDefaults = (data: Data): void => {
    console.log('apply defaults')
    const set = <T extends object, K extends keyof T, V extends T[K]>(o: T, key: K, value: V): void => {
        if(!(key in o) || (o[key] == null)) {
            console.log(`setting ${key.toString()} to ${value?.toString() ?? ''}`)
            o[key] = value
        }
    }
    set(data, 'logo_url', defaults.common.logo_url)
    set(data, 'audio_volume', defaults.common.audio_volume)
    set(data, 'closed_captions', defaults.common.closed_captions)
    set(data, 'h264_quality', defaults.common.h264_quality)
    set(data, 'create_tar_file', defaults.common.create_tar_file)
    set(data, 'gop_length', defaults.common.gop_length)
    set(data, 'scte35_pid_remap', defaults.common.scte35_pid_remap)
    set(data, 'ts_mode_input_audio_source_stream_count', defaults.common.ts_mode_input_audio_source_stream_count)
    set(data, 'video_aspect_ratio', defaults.common.video_aspect_ratio)
    set(data, 'rotation_blackness', defaults.common.rotation_blackness)
    set(data, 'scte35_passthough', defaults.common.scte35_passthough)
    set(data, 'ip_distance', defaults.common.ip_distance)
    set(data, 'segment_length', defaults.common.segment_length)

    data.audioProfiles.forEach(p => {
        set(p, 'audio_bitrate', defaults.audio.audio_bitrate)
        set(p, 'audio_channels', defaults.audio.channels)
        set(p, 'audio_codec', defaults.audio.audio_codec == 'aaclc' ? 'aac' : defaults.audio.audio_codec)
        set(p, 'dolby_digital_dialnorm', defaults.audio.dolby_digital_dialnorm)
        // set(p, 'primary_audio_downmix_to_stereo', defaults.audio.primary_audio_downmix_to_stereo_flag)
        set(p, 'source_stream', defaults.audio.source_stream)
    })
}

export const Main = (p: { mainState: MainState }) => {
    const { rep, client, urls, saveTemplateSignal, user } = useAuthenticatedContext()

    const fileDropdownSelectedIdSignal = createSignal<string>()

    const MONTHLY_UPLOAD_QUOTA_GB = user()?.plan == 'pro'
        ? 100
        : 50

    const select = { setValue: (a: any) => {} }

    const addNewVideoProfile = (width: number | null, height:number | null, video_bitrate: number | null = null, data: Data) => {
        const x = getLimits()[data.output_container!][data.video_codec!].def({ width: width ?? 1280, height: height ?? 720 })
        console.log('limit', x)
        const video_bitrate_ = video_bitrate ?? x
        data.videoProfiles.push({
            output         : null as any,
            width          : width as any,
            height         : height as any,
            video_bitrate  : video_bitrate_,
            // video_bitrate_mode: defaults.video.video_bitrate_mode == 0 ? 'cbr' :  'vbr',
            // video_format      : defaults.video.video_format,
            video_framerate: '1x',
            audio_profiles : '0',
        })
        const i = data.videoProfiles.length - 1
        data.videoProfiles[i].video_bitrate = Math.max(250, Math.max(minBitrate(data, i, 1.5), data.videoProfiles[i].video_bitrate ?? 0))
    }
    const addNewAudioProfile = (data: Data) => data.audioProfiles.push({
        audio_bitrate         : defaults.audio.audio_bitrate,
        audio_channels        : defaults.audio.channels,
        audio_codec           : defaults.audio.audio_codec == 'aaclc' ? 'aac' : defaults.audio.audio_codec,
        dolby_digital_dialnorm: defaults.audio.dolby_digital_dialnorm,
        // primary_audio_downmix_to_stereo: defaults.audio.primary_audio_downmix_to_stereo_flag,
        source_stream         : 1, // https://igolgi.atlassian.net/jira/software/c/projects/CU/boards/25?selectedIssue=CU-28
    })

    const defaultConfig: Data = {
        videoProfiles                          : [],
        separate_audio                         : false,
        segmented_output_dash                  : false,
        output_container                       : 'mp4',
        picture_transform                      : 'none',
        // force_progressive                      : true,
        // keep_fps                               : true,
        logo_url                               : defaults.common.logo_url,
        // audio_output_path                      : undefined,
        audio_volume                           : defaults.common.audio_volume,
        // blazar_mode                            : undefined,
        // bucket_output_path                     : undefined,
        closed_captions                        : defaults.common.closed_captions,
        create_tar_file                        : defaults.common.create_tar_file,
        gop_length                             : defaults.common.gop_length,
        h264_quality                           : 'better',
        scte35_pid_remap                       : defaults.common.scte35_pid_remap,
        segment_length                         : defaults.common.segment_length,
        ts_mode_input_audio_source_stream_count: defaults.common.ts_mode_input_audio_source_stream_count, // https://igolgi.atlassian.net/jira/software/c/projects/CU/boards/25?selectedIssue=CU-12
        video_aspect_ratio                     : defaults.common.video_aspect_ratio,
        rotation_blackness                     : defaults.common.rotation_blackness,
        video_codec                            : 'h.264',

        scte35_passthough   : defaults.common.scte35_passthough,
        segmented_output_hls: false,
        ip_distance         : defaults.common.ip_distance,
        audioProfiles       : [],
        input               : null as any,
        // front_write_back_read: { free_version: free_version },
        cloud_credentials   : {
            input: { cloud_provider : 'file-upload',
                     access_key     : null as any,
                     secret_key     : null as any,
                     region         : 'ca-toronto-1',
                     //
                     tenancy_ocid   : null as any,
                     oci_fingerprint: null as any,
                     user_ocid      : null as any,
            },
            output: { cloud_provider : 'igolgi-store',
                      access_key     : null as any,
                      secret_key     : null as any,
                      region         : 'ca-toronto-1',
                      //
                      tenancy_ocid   : null as any,
                      oci_fingerprint: null as any,
                      user_ocid      : null as any,
            } } }
    applyDefaults(defaultConfig)
    addNewVideoProfile(1280, 720, 3500, defaultConfig)
    addNewAudioProfile(defaultConfig)


    if(p.mainState.data == undefined){
        p.mainState.data = createMutable<Data>(clone(defaultConfig))
    }
    const data = p.mainState.data

    createEffect(async () => {
        const t = saveTemplateSignal[0]()
        if(t != undefined && Math.abs(t - Date.now()) < 0.1) {
            await saveTemplateAction()
        }
    })


    // FIXES: https://igolgi.atlassian.net/jira/software/c/projects/CU/boards/25
    const ls = localStorage.getItem(JOB_CONFIG_CACHE_KEY)
    if(ls != null && ls.length > 0) {
        const ls_ = JSON.parse(ls) as Data
        Object.assign(data, ls_)
        applyDefaults(data)
        if(ls_.cloud_credentials.input.cloud_provider == 'file-upload') {
            const m = /.*uploads\/(.*)/.exec(ls_.input ?? '')
            if(m != undefined) {
                setTimeout(() => fileDropdownSelectedIdSignal[1](m[1]), 100)
            }
        }

    } else {
        // addNewVideoProfile(720, 576, null, data)
        // addNewAudioProfile(data)
    }

    const suggestNextTemplateName = (start: string): string => {
        let defaultTemplateName: string = ''
        const match = start.match(/^(.*) \((\d+)\)$/)
        const base = match == null ? start : match[1]
        const names = getTemplates().map(x => x[1].name)
        let i = Number(match == null ? 1 : match[2])
        while(true) {
            i += 1
            defaultTemplateName = `${base} (${i})`
            // debugger
            if(names.indexOf(defaultTemplateName) == -1) return defaultTemplateName
        }
        return defaultTemplateName
    }
    const saveTemplateAction = async () => {
        let defaultTemplateName: undefined | string = undefined
        if(p.mainState.template != null && p.mainState.template.id != 'default') {
            defaultTemplateName = suggestNextTemplateName(p.mainState.template.name)
            // const match = p.mainState.template.name.match(/^(.*) \((\d+)\)$/)
            // console.log(match)
            // if(match == null) {
            //     defaultTemplateName = p.mainState.template.name + ' (2)'
            // } else {
            //     defaultTemplateName = match[1] + ` (${Number(match[2]) + 1})`
            // }
        }
        const name = prompt('New Template Name', defaultTemplateName)
        if(name != null && name.length > 0) {
            const newId = nanoid()
            console.log('before', templateNames().length)
            await rep()?.mutate.templateAdd({ id: newId, body: JSON.stringify(data), name })
            console.log('after', (templateNames().length), templateNames())
            setTimeout(() => {
                const newTemplate = templateNames().find(x => (x  as { id: string }).id == newId)
                console.log('newTemplate', newTemplate)
                if(newTemplate != undefined){
                    select.setValue(newTemplate)
                }
            }, 100)
            toast('✅ Your template has been saved')
        }
    }

    const uploadStatus = (): 'warning' | 'suspended' | 'safe' => {
        if(uploadSizeThisMonthGb() / MONTHLY_UPLOAD_QUOTA_GB >= 1) return 'suspended'
        else if(uploadSizeThisMonthGb() / MONTHLY_UPLOAD_QUOTA_GB >= 0.8) return 'warning'
        else return 'safe'
    }

    const deleteVideoProfile = (i: number) => data.videoProfiles.splice(i, 1)
    const deleteAudioProfile = (i: number) => data.audioProfiles.splice(i, 1)

    ;(window as (Window & typeof globalThis & { setOpts: (b: object) => void })).setOpts = (body: object) => {
        Object.assign(data, body)
    }
    const keys = signalFromPrefix<string>(rep, 'key/')
    const [errors, setErrors] = createSignal<z.ZodError<Data> | undefined>(undefined)
    const [templateSearchString, setTemplateSearchString] = createSignal('')
    const [sortTemplateOrder, setTemplateOrder] = createSignal<'az' | 'za'>('az')

    // const [uploadingIsInProgress, setUploadingIsInProgress] = createSignal<boolean>(false)

    createEffect(() => {
        p.mainState.canNavigate = !p.mainState.isUploading// !uploadingIsInProgress()
    })
    const [onRunErrors, setOnRunErrors] = createSignal<string | undefined>(undefined)
    const codeSnippet = () => getCodeSnippet(data, urls.base, keys().length > 0 ? keys()[0][1] : 'no-key-found')
    // const coderSnippetColorized = () => {
    //     return hljs.highlight(
    //         codeSnippet(),
    //         { language: 'json'  },
    //     ).value
    // }
    // const [source, setSource] = createSignal<commonSchema.CloudTag>('oracle')

    // Job status
    const [jobId, setJobId] = createSignal<{ id: undefined | Job.Id, date: Date }>({ id: undefined, date: new Date() })

    // @ts-expect-error
    const getJobs = signalFromPrefix<[string, commonSchema.JobModel]>(rep, 'job/')
    const job = () => {
        const id = jobId().id
        if(id != undefined) {
            return getJobs().find(j => j[1]._auto_generated_id_ == id)?.[1]
        }
        return undefined
    }


    const progress = createMemo(() => {// don't trigger when no changes
        const delta = 0//((new Date().getTime()) - jobId().date.getTime()) / 50000
        const p = computeProgress(job) + delta
        return p
    })
    const progressStatus = createMemo<null | 'Transferring' | 'Transcoding' | 'Downloading' | 'Uploading'>(() => {
        console.log('progress', progress())
        if(jobId().id == undefined) return null
        if(progress() <= 5) return 'Downloading'
        else if(progress() < 50) return 'Transferring'
        else if(progress() >= 99) return 'Uploading'
        else return 'Transcoding'
    })

    createEffect(() => {
        const state = computeState(job()?.status ?? null, -1)
        const err = job()?.streamengine_job_get_response?.error_description
        if(progress() == 50) toast('Your job assets have been transferred and transcoding has begun.', { icon: '✅' })
        if(state == 'abort') {
            const msg = 'Your job has aborted.'
            toast(msg, { icon: '❌', duration: 7000  })
            setOnRunErrors(msg)
            setJobId({ id: undefined, date: new Date() })
        } else if(state == 'error' || (err != undefined && err.length > 0)) {
            const M = 'Your job encountered an error. Check the job history page for details.'
            const msg = err == undefined
                ? M
                : `${M}. Error: ${addInvisibleBreaks(err ??  'Error', 30)}`
            toast(msg, { icon: '❌', duration: 7000 })
            setOnRunErrors(msg)
            setJobId({ id: undefined, date: new Date() })
        }
        else if(progress() == 100) {
            toast('Your job has finished.', { icon: '✅', duration: 7000 })
            setOnRunErrors(undefined)
            setJobId({ id: undefined, date: new Date() })
        }
    })


    const TemplateSortZA = () => <div class={sortButton(sortTemplateOrder() == 'za')} style={{ width: '1em' }}  onClick={() => setTemplateOrder('za')}><SortZA></SortZA></div>
    const TemplateSortAZ = () => <div class={sortButton(sortTemplateOrder() == 'az')} style={{ width: '1em' }} onClick={() => setTemplateOrder('az')}><SortAZ></SortAZ></div>


    const defaultTemplate = ['template/default', { body: JSON.stringify(defaultConfig), name: 'Default Configuration', id: 'default' }] as [id: string, t: commonSchema.Template]
    const getTemplates_ = signalFromPrefix<[id: string, t:commonSchema.Template]>(rep, 'template/')
    const getTemplates: Accessor<[id: string, t: commonSchema.Template][]> = () => {
        return [defaultTemplate, ...getTemplates_().sort((a, b) => (sortTemplateOrder() == 'az' ? 1 : -1) * a[1].name.localeCompare(b[1].name))]
    }
    p.mainState.template = defaultTemplate[1]

    const getFiles = signalFromPrefix<[id: string, t:commonSchema.File]>(rep, 'file/')

    createEffect(() => console.log('files', getFiles()))
    const uploadSizeThisMonthGb = (): number => {
        const currentDate = new Date()
        const val = getFiles()
            .filter(x => x[1].size != null && x[1].created_at != null && new Date(x[1].created_at)?.getUTCMonth() == currentDate.getUTCMonth())
            .reduce((acc, cur) => acc + (cur[1]).size!, 0) / (1024 ** 3)

        return val
    }


    // createEffect(() => console.log('data.cloud_credentials.cloud_provider', data.cloud_credentials.cloud_provider, data.cloud_credentials.cloud_provider == 'file-upload'))

    // const templateSaveAs = <span style={{ 'font-weight': '600' }}>Save As New Template</span>
    // const templateUpdate = <span style={{ 'font-weight': '600' }}>Update Current Template</span>
    const templateNames  = (match?: string): (JSX.Element & { id: string })[] => {
        const tmps = getTemplates()
            .filter(x => match == undefined ? true : x[1].name.toLowerCase().indexOf(match.toLowerCase()) > -1)
            .map(([id, t]) => {
                const out = <span><span style={{ color: '#aaa' }}></span> {t.name}</span>
            ;(out as any as { id: string }).id = t.id
                return out
            })
        return [...tmps] as (JSX.Element & { id: string })[]
    }
    // const [template, setTemplate] = createSignal<commonSchema.Template | undefined>(undefined)

    const pathLabel = (cloud_provider: commonSchema.CloudTag): string => {
        let protocol: string = cloud_provider
        if(cloud_provider == 'oracle') protocol = 'oci'
        else if(cloud_provider == 'aws') protocol = 's3'
        else if(cloud_provider == 'gcloud') protocol = 'gs'

        let pString = ''
        if(protocol == 'http') {
            pString = 'http:// or https://...'
        } else {
            pString = `${protocol}://...`
        }
        return `Input Location (${pString})`
    }

    createEffect(() => {
        // untrack(() => applyDefaults(data))
        data.audioProfiles.forEach((p) => {
            if(p.audio_codec == 'ac3' && p.dolby_digital_dialnorm == undefined) {
                p.dolby_digital_dialnorm = defaults.audio.dolby_digital_dialnorm
            }
        })
        const err1 = enforceConstraints(data)
        const op = commonSchema.simplifiedJobInput.safeParse(data)

        const err2 = op.success ? undefined : op.error
        setErrors(unifyZodErrors(err1, err2))
        console.log(err2)

        localStorage.setItem(JOB_CONFIG_CACHE_KEY, JSON.stringify(data))
        // enforceConstraints(data)
        // console.log('enforceConstraints', err)
    })
    data.ts_mode_input_audio_source_stream_count = 1
    // setInterval(() => {
    //     data.ts_mode_input_audio_source_stream_count = 1
    //     console.log(data.ts_mode_input_audio_source_stream_count)
    // }, 100)

    // const [collapsed, setCollapsed] = createSignal(false)

    const templateInitialValue = () => templateNames().find(x => (x  as { id: string }).id == p.mainState.template?.id)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return <main class={main}>
        <Content>
            <h1 classList={{ [header]: true, [container(1024)]: true }}
                style={{ display: 'flex', flex: '1 1 auto', 'justify-content': 'space-between' }}>
                <span>
                    <span style={{ 'font-size': '0.75em', color: 'blue', cursor: 'pointer', 'padding-right': '0.1em' }} onClick={() => {
                        p.mainState.collapsed = !p.mainState.collapsed
                        Object.values(p.mainState.panelStates).map(s => s[1](p.mainState.collapsed))
                    }}>{!p.mainState.collapsed ? '▶' : '▼'}</span>
                    <span>Start a Transcoding Job</span></span>
                <div style={{  right: '0px', top: '0px', display: 'flex', gap: '3px' }}>
                    <Select
                        setValueRef={(setValue) => {
                            select.setValue = setValue
                        }}
                        onInput={e => {
                            setTemplateSearchString(e)
                        }}
                        options={() => templateNames(templateSearchString())}
                        class={templates()}
                        initialValue={templateInitialValue}
                        placeholder='Template'
                        onChange={e => {
                            console.log('INPUT')
                            const id = (e  as { id: string }).id
                            const t = getTemplates().filter(x => x[0] == `template/${id}`)[0]?.[1]
                            console.log('template found', t)
                            if(t == undefined) return

                            p.mainState.template = t

                            const body = JSON.parse(t.body) as commonSchema.SimplifiedJobInput
                            // remove extraneous keys in case schema has diverged from template
                            const bodyMask = removeExtraKeys(commonSchema.simplifiedJobInput, body) as Data
                            console.log(bodyMask)
                            if('force_progressive' in bodyMask || 'keep_fps' in bodyMask) {
                                const picture_transform = parse_picture_transform((data as any as { keep_fps: boolean | undefined }).keep_fps ?? false, (data as any as { force_progressive: boolean | undefined }).force_progressive ?? false)
                                if(bodyMask.picture_transform == undefined) {
                                    bodyMask.picture_transform = picture_transform
                                }
                            }
                            if('h264_quality' in bodyMask && bodyMask.h264_quality != undefined && !isNaN(parseFloat(bodyMask['h264_quality']))) {
                                const map = ['good', 'good', 'better', 'better', 'best', 'best'] as const
                                bodyMask.h264_quality = ((bodyMask.h264_quality as any as number) <= 0 && (bodyMask.h264_quality as any as number) < map.length)
                                    ? map[(bodyMask.h264_quality as any as number)]
                                    : 'better'

                            }
                            applyDefaults(data)
                            Object.assign(data, bodyMask)
                            if((data.video_aspect_ratio as any) == 0) {
                                data.video_aspect_ratio = 'passthrough'
                            }
                            if(data.segmented_output_hls == true || data.segmented_output_dash == true) {
                                if(data.segment_length == undefined) {
                                    data.segment_length = defaults.common.segment_length
                                }
                            }
                            applyDefaults(data)

                            if(bodyMask.cloud_credentials.input.cloud_provider == 'file-upload') {
                                const m = /.*uploads\/(.*)/.exec(bodyMask.input ?? '')
                                if(m != undefined) {
                                    setTimeout(() => fileDropdownSelectedIdSignal[1](m[1]), 100)
                                }
                            }

                            const err = enforceConstraints(data)
                            console.log('enforceConstraints', err)
                            setErrors(err)


                        }}
                    />
                    <TemplateSortAZ></TemplateSortAZ>
                    <TemplateSortZA></TemplateSortZA>
                    <button
                        style={{ 'white-space': 'nowrap', 'font-size': '0.5em', width: 'max-content', padding: '5px', height: 'min-content' }}
                        onClick={() => {
                            escapeOwner(saveTemplateAction)
                        }}>Save As</button>
                    <button style={{ 'font-size': '0.5em', width: 'max-content', padding: '5px', height: 'min-content' }}
                        disabled={p.mainState.template?.id == 'default'}
                        onClick={() => {
                            escapeOwner(async () => {
                                if(p.mainState.template != undefined && p.mainState.template?.id != 'default'){
                                    await rep()?.mutate.templateAdd({ id: p.mainState.template.id, body: JSON.stringify(data), name: p.mainState.template.name })
                                }})
                            toast('✅ Your template has been updated')
                        }}>Save</button>
                </div>
            </h1>
            <div class={container(768)} style={{ display: 'flex', gap: '1em' }}>
                <div id="left"style={{ flex: '1 1 auto', position: 'relative' }}>
                    <Group name="Input Location" state={p.mainState.panelStates.input}>
                        <CloudCreds
                            errors={errors}
                            urls={urls}
                            where={'input'}
                            data={data}
                            getFiles={getFiles}
                            fileDropdownSelectedIdSignal={fileDropdownSelectedIdSignal}
                            narrow={['aws', 'gcloud', 'oracle', 'http', /*'nfs', 'ftp',*/ 'file-upload']}
                            middle={<>
                                <Show when={data.cloud_credentials.input.cloud_provider != 'file-upload'}>
                                    <Row path=""
                                        conditional errors={errors} dict={data} label={pathLabel(data.cloud_credentials.input.cloud_provider)} obj={simplifiedJobInput} key="input"></Row>
                                </Show>
                            </>}/>
                        <Show when={uploadStatus() != 'suspended'}>
                            <Upload
                                suffix=""
                                showUploadArea={data.cloud_credentials.input.cloud_provider == 'file-upload'}
                                setUploadingIsInProgress={(f: boolean) => p.mainState.isUploading = f}
                                onFileUpload={async (e) => {
                                    await rep()?.mutate.fileAdd(e) // this does nothing. it's ok, the push happens from uppy
                                    fileDropdownSelectedIdSignal[1](e.id)
                                    data.cloud_credentials.input.cloud_provider = 'file-upload'
                                    data.input = `${urls.base}/uploads/${e.id}`
                                //
                                }}></Upload>
                        </Show>
                        <UploadWarning uploadStatus={uploadStatus()} quota={MONTHLY_UPLOAD_QUOTA_GB}/>
                    </Group>
                    <Group name="Output Format" state={p.mainState.panelStates.outputFormat}>
                        <Format data={data}></Format>
                    </Group>
                    {/* <Group name="Logo" initial={true}>
                        <Upload suffix="_logo" showUploadArea={true}
                            onFileUpload={async (e) => {
                                // await rep()?.mutate.fileAdd(e)
                                // data.input = `${urls.base}/uploads/${e.id}`
                            }}></Upload>
                    </Group> */}
                    <Group name="Common Output Settings" state={p.mainState.panelStates.output}>
                        <CloudCreds errors={errors} urls={urls} where={'output'} data={data} getFiles={getFiles} narrow={['aws', 'oracle', 'gcloud', 'igolgi-store']}/>
                        {/* <Show when={data.master_variant_mode}>
                            <Row dict={data} obj={simplifiedJobInput} label="Output Tag" key="output_tag"></Row>
                        </Show> */}
                        {/* <Show when={data.master_variant_mode == false && data.output_container == 'ts'}> */}
                        <Row path="" conditional errors={errors} dict={data} obj={simplifiedJobInput} label="Audio Source Stream Count" key="ts_mode_input_audio_source_stream_count"></Row>
                        <Row path="" conditional errors={errors} dict={data} obj={simplifiedJobInput} label="Audio Output Path" key="audio_output_path"></Row>
                        {/* <Show when={data.master_variant_mode == true && data.output_container == 'mp4' && (data.segmented_output_hls == true || data.segmented_output_dash == true)}> */}
                        <Row path="" conditional errors={errors} dict={data} obj={simplifiedJobInput} label="Bucket Output Path" key="bucket_output_path"></Row>
                        {/* <Row path="" conditional errors={errors} dict={data} obj={simplifiedJobInput} label="Output Subpath" key="output_subpath"></Row> */}
                        {/* </Show> */}
                        {/* <Show when={data.master_variant_mode == true && data.output_container == 'mp4' && (data.segmented_output_hls == true || data.segmented_output_dash == true)}> */}
                        <table style={{ width: '100%', 'border-spacing': '0px 0.5em',  'table-layout': 'fixed' }}>
                            <thead>
                                <tr>
                                    <Show when={'segment_length' in data}>
                                        <Th obj={simplifiedJobInput} key="segment_length">Segment Length</Th>
                                    </Show>
                                    {/* <Show when={'video_codec' in data}> */}
                                    <Th obj={simplifiedJobInput} key="video_codec">Video Codec</Th>
                                    {/* </Show> */}
                                    <Show when={'picture_transform' in data}>
                                        <Th obj={simplifiedJobInput} key="picture_transform">Picture Transform</Th>
                                    </Show>
                                    {/* <Show when={data.force_progressive == true}>
                                        <Th obj={simplifiedJobInput} key="keep_fps">Keep FPS</Th>
                                    </Show> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ outline: '1px solid #aaaaff', 'border-radius': '3px', padding: '2px' }}>
                                    <Show when={'segment_length' in data}>
                                        <Row cssClass={inlineRow} path="" conditional errors={errors} dict={data} obj={simplifiedJobInput} label="Segment Length" key="segment_length"></Row>
                                    </Show>
                                    {/* </Show> */}
                                    {/* <Row path="" disabled errors={errors} dict={data} obj={simplifiedJobInput} label="Blazar Mode"  key="blazar_mode"></Row> */} {/* Jeff decided to hide on Mar 28, 2024 */}
                                    {/* <Show when={'video_codec' in data}> */}
                                    <Row cssClass={inlineRow} path="" errors={errors} dict={data} obj={simplifiedJobInput} label="Video Codec" key="video_codec"></Row>
                                    {/* </Show> */}
                                    <Show when={'picture_transform' in data}>
                                        <Row cssClass={inlineRow} conditional path="" errors={errors} dict={data} label="Force Progressive"  obj={simplifiedJobInput} key="picture_transform"></Row>
                                    </Show>
                                    {/* <Show when={data.force_progressive == true}>
                                        <Row cssClass={inlineRow} conditional path="" errors={errors} dict={data} label="Keep FPS"  obj={simplifiedJobInput} key="keep_fps" disabled={data.force_progressive == false}></Row>
                                    </Show> */}
                                </tr>
                            </tbody>
                        </table>

                        <div></div>
                        <Show when={data.cloud_credentials.output.cloud_provider == 'igolgi-store'}>
                            Igolgi provides complimentary storage of job outputs for a period of 24 hours, after which they will be deleted.
                        </Show>
                    </Group>
                    {/* <div><button>Discover</button></div> */}
                    {/* <br></br> */}
                    <Group name={`Audio Profiles`}
                        state={p.mainState.panelStates.audio}
                        // style={{ 'margin-bottom': i() == data.audioProfiles.length - 1 ? '0em' : '0.5em' }}
                        // topRight={
                        //     data.audioProfiles.length > 1
                        //         ? <span class={topRightStyle} onClick={_ => deleteAudioProfile(i())}>Delete</span>
                        //         : null
                        // }
                    >

                        <ErrorHint error={(errors()?.errors ?? []).filter(x => x.path[0] == 'audioProfiles' && x.path.length == 1)?.[0]?.message}>
                            <table style={{ width: '100%', 'border-spacing': '0px 0.5em' }}>
                                <Show when={data.audioProfiles.length > 0}>
                                    <thead>
                                        <tr>
                                            <Th obj={commonSchema.simplifiedAudioProfile} key="audio_codec">Codec</Th>
                                            <Th obj={commonSchema.simplifiedAudioProfile} key="audio_channels" style={{ width: '6em' }}>Channels</Th>
                                            <Show when={data.output_container != 'ts'}>
                                                <Th obj={commonSchema.simplifiedAudioProfile} key="source_stream">Source Stream</Th>
                                            </Show>
                                            <Th obj={commonSchema.simplifiedAudioProfile} key="audio_bitrate">Bitrate (kbps)</Th>
                                            {/* <Th obj={commonSchema.simplifiedAudioProfile} key="primary_audio_downmix_to_stereo">Downmix to Stereo</Th> */}
                                            {/* <Show when={data.audioProfiles[i()].audio_codec == 'ac3'}> */}
                                            <Th obj={commonSchema.simplifiedAudioProfile} key="dolby_digital_dialnorm" style={{ width: '5em' }}>Dialnorm</Th>
                                            <th class={th}></th>
                                            {/* </Show> */}
                                        </tr>
                                    </thead>
                                </Show>
                                <tbody>
                                    <For each={data.audioProfiles}>{(e, i) =>
                                        <tr style={{ outline: '1px solid #aaaaff', 'border-radius': '3px', padding: '2px' }}>
                                            <Row cssClass={inlineRow} path={`audioProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedAudioProfile} dict={data.audioProfiles[i()]} label="Audio Codec" key="audio_codec" moreStyle={{ 'width': '4em' }}></Row>
                                            <Row cssClass={inlineRow} path={`audioProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedAudioProfile} dict={data.audioProfiles[i()]} label="Audio Channels" key="audio_channels" moreStyle={{ 'width': '2em' }}></Row>
                                            <Show when={data.output_container != 'ts'}>
                                                <Row cssClass={inlineRow} path={`audioProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedAudioProfile} dict={data.audioProfiles[i()]} label="Source Stream" key="source_stream" ></Row>
                                            </Show>
                                            <Show when={data.audioProfiles[i()].audio_codec == 'aac'}>
                                                <Row cssClass={inlineRow} path={`audioProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedAudioProfile} dict={data.audioProfiles[i()]} label="Bitrate (kbps)" key="audio_bitrate" override={commonSchema.bitrates_aac}></Row>
                                            </Show>
                                            <Show when={data.audioProfiles[i()].audio_codec == 'ac3'}>
                                                <Row cssClass={inlineRow} path={`audioProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedAudioProfile} dict={data.audioProfiles[i()]} label="Bitrate (kbps)" key="audio_bitrate" override={commonSchema.bitrates_ac3}></Row>
                                            </Show>
                                            {/* <Show when={data.audioProfiles[i()].audio_codec == 'mp2'}>
                                                <Row cssClass={inlineRow} path={`audioProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedAudioProfile} dict={data.audioProfiles[i()]} label="Bitrate (kbps)" key="audio_bitrate" override={commonSchema.bitrates_ac3}></Row>
                                            </Show> */}

                                            {/* <Row cssClass={inlineRow} path={`audioProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedAudioProfile} dict={data.audioProfiles[i()]} label="Downmix to Stereo" key="primary_audio_downmix_to_stereo"></Row> */}
                                            <Show when={data.audioProfiles[i()].audio_codec == 'ac3'} fallback={<div class={inlineRow} style={{ margin: '1px', 'border': '1px solid transparent' }}>
                                                <div classList={{ 'input': true, 'row': true }}><Inputs><input disabled></input></Inputs></div></div>}>
                                                <Row cssClass={inlineRow} path={`audioProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedAudioProfile} dict={data.audioProfiles[i()]} label="Dolby Digital Dialnorm" key="dolby_digital_dialnorm"></Row>
                                            </Show>
                                            <td class={deleteTd}>
                                                <Show when={data.audioProfiles.length > 0}>
                                                    <div style={{ cursor: 'pointer' }}>
                                                        <svg onclick={() => {
                                                            deleteAudioProfile(i())
                                                            data.videoProfiles.forEach(p => {
                                                                if(p.audio_profiles != undefined){
                                                                    // Todo: add constraint to throw error if audio_profiles field lists an audio stream not prsent in the json
                                                                    p.audio_profiles = p.audio_profiles.split(',').filter(x => Number(x) != i()).join(',')
                                                                }
                                                            })
                                                        }} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24">
                                                            <path d="M 10.806641 2 C 10.289641 2 9.7956875 2.2043125 9.4296875 2.5703125 L 9 3 L 4 3 A 1.0001 1.0001 0 1 0 4 5 L 20 5 A 1.0001 1.0001 0 1 0 20 3 L 15 3 L 14.570312 2.5703125 C 14.205312 2.2043125 13.710359 2 13.193359 2 L 10.806641 2 z M 4.3652324 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652324 7 z"></path>
                                                        </svg>
                                                    </div>
                                                </Show>
                                            </td>
                                        </tr>


                                    }</For>
                                </tbody>
                                <tfoot>
                                    <tr><td colSpan={5}>
                                        <Show when={data.audioProfiles.length < MAX_NUMBER_OF_AUDIO_PROFILES}>
                                            <a
                                                style={{ color: 'blue', 'font-size': '0.8em', cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    addNewAudioProfile(data)}}>⊕ Audio Profile</a>
                                        </Show>
                                    </td></tr>
                                </tfoot>
                            </table>
                        </ErrorHint>
                    </Group>

                    <Group name={`Video Profiles`}
                        state={p.mainState.panelStates.video}
                        // style={{ 'margin-bottom': i() == data.videoProfiles.length - 1 ? '0em' : '0.5em' }}
                        // topRight={
                        //     data.videoProfiles.length > 1
                        //         ? <span class={topRightStyle} onClick={_ => deleteVideoProfile(i())}>Delete</span>
                        //         : null
                        // }
                    >
                        <select onChange={(e) => {
                            e.preventDefault()
                            const { profiles } = presets.find(x => x.name == e.currentTarget.value)!
                            if(profiles != undefined){
                                data.videoProfiles = []
                                profiles.forEach(p => {
                                    const { width, height, h264Bitrate, hevcBitrate } = p
                                    const bitrate =  data.video_codec == 'h.264' ? h264Bitrate : hevcBitrate
                                    if(bitrate != null) {
                                        addNewVideoProfile(width, height, bitrate, data)
                                    }
                                })
                            }
                        }}>
                            <option>⊕ Video Defaults</option>
                            <For each={presets.filter(x => x.profiles.filter(y => data.video_codec == 'h.264' ? y.h264Bitrate != null : true).length > 0)}>{(e, i) => <option id={e.name}>{e.name}</option>}</For>
                        </select>
                        <Show when={new Set(data.videoProfiles.filter(x => x.height != 0 && x.width != 0).map(x => Math.round(x.height / x.width * 10))).size > 1}>
                            <div style={{ padding: '0.2em' }}><span style={{ 'font-weight': 800, 'text-transform': 'uppercase', 'font-size': '0.8em', color: 'hsl(30deg 100% 50%)' }}>Warning</span> <span style={{ color: 'hsl(30deg 100% 40%)' }}>Output resolutions do not have consistent aspect ratios.</span></div>
                        </Show>
                        <Show when={data.videoProfiles.filter(x => x.height != 0 && x.width != 0 && ((x.height / x.width >= 7) || (x.height / x.width <= 1 / 7))).length > 0}>
                            <div style={{ padding: '0.2em' }}><span style={{ 'font-weight': 800, 'text-transform': 'uppercase', 'font-size': '0.8em', color: 'hsl(30deg 100% 50%)' }}>Warning</span> <span style={{ color: 'hsl(30deg 100% 40%)' }}>Some of your output resolutions exceed the maximum aspect ratio of 7:1.</span></div>
                        </Show>
                        <For each={data.videoProfiles}>{(e, i) => {
                            return <div style={{ outline: '1px solid #aaaaff', 'margin-bottom': '0.5em', padding: '3px', 'border-radius': '3px' }}>
                                <Show when={data.cloud_credentials.output.cloud_provider != 'igolgi-store'}>
                                    {/* <Show when={data.master_variant_mode == true && data.output_container == 'mp4' && (data.segmented_output_hls == true || data.segmented_output_dash == true)}> */}
                                    {/* <Row conditional path={`videoProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedVideoProfile} dict={data.videoProfiles[i()]} label="Profile Name" key="profile_name"></Row> */}
                                    {/* </Show> */}
                                    {/* <Show when={!(data.master_variant_mode == true && data.output_container == 'mp4' && (data.segmented_output_hls == true || data.segmented_output_dash == true))}> */}
                                    <Row conditional path={`videoProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedVideoProfile} dict={data.videoProfiles[i()]} label="Output Location" key="output" ></Row>
                                    {/* </Show> */}
                                </Show>
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <Th obj={commonSchema.simplifiedVideoProfile} key="width">Width</Th>
                                            <Th obj={commonSchema.simplifiedVideoProfile} key="height">Height</Th>
                                            <Th obj={commonSchema.simplifiedVideoProfile} key="video_bitrate">Bitrate (kbps)</Th>
                                            <Th obj={commonSchema.simplifiedVideoProfile} key="video_framerate">Framerate</Th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <HoverOnClick
                                                className={resolutionPanel}
                                                trigger={
                                                    <a style={{ color: 'blue', 'font-size': '0.8em', border: '0px solid blue', 'border-radius': '5px', cursor: 'pointer' }}
                                                    >✎</a>
                                                }>
                                                <For each={data.video_codec == 'hevc' ? pixels.all : pixels.all}>{(e) =>
                                                    <div
                                                        onClick={() => {
                                                            data.videoProfiles[i()].width = e[0]
                                                            data.videoProfiles[i()].height = e[1]
                                                        }}>
                                                        {e[0]} × {e[1]}
                                                    </div>}</For>
                                            </HoverOnClick>
                                            <Row cssClass={inlineRow} path={`videoProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedVideoProfile} dict={data.videoProfiles[i()]} label="Width" key="width" step={8}></Row>
                                            <Row cssClass={inlineRow} path={`videoProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedVideoProfile} dict={data.videoProfiles[i()]} label="Height" key="height" step={8}></Row>
                                            <Row cssClass={inlineRow} path={`videoProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedVideoProfile} dict={data.videoProfiles[i()]} label="Video Bitrate (kbps)" key="video_bitrate" ></Row>
                                            {/* <Row cssClass={inlineRow} path={`videoProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedVideoProfile} dict={data.videoProfiles[i()]} label="Video Bitrate Mode" key="video_bitrate_mode" ></Row> */}
                                            <Row cssClass={inlineRow} path={`videoProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedVideoProfile} dict={data.videoProfiles[i()]} label="Framerate" key="video_framerate" valueMap={{ '1x': '1x', '1/2x': '1/2x', '2x': '2x from 1080i2997/25' }}></Row>
                                            <Show when={data.videoProfiles.length > 1}>
                                                <td class={deleteTd}>
                                                    <div style={{ cursor: 'pointer' }}>
                                                        <svg onclick={() => deleteVideoProfile(i())} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24">
                                                            <path d="M 10.806641 2 C 10.289641 2 9.7956875 2.2043125 9.4296875 2.5703125 L 9 3 L 4 3 A 1.0001 1.0001 0 1 0 4 5 L 20 5 A 1.0001 1.0001 0 1 0 20 3 L 15 3 L 14.570312 2.5703125 C 14.205312 2.2043125 13.710359 2 13.193359 2 L 10.806641 2 z M 4.3652324 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652324 7 z"></path>
                                                        </svg>
                                                    </div>
                                                </td>
                                            </Show>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* <Row path={`videoProfiles.${i()}`} errors={errors} obj={commonSchema.simplifiedVideoProfile} dict={data.videoProfiles[i()]} label="Video Format" key="video_format" ></Row> */}
                                {/* <Show when={data.master_variant_mode == false && data.output_container == 'mp4'}> */}
                                <Row path={`videoProfiles.${i()}`} conditional errors={errors} obj={commonSchema.simplifiedVideoProfile} dict={data.videoProfiles[i()]} label="Audio Profile" key="audio_profiles" arrayValues={data.audioProfiles.map((a, i) => i + 1)}></Row>
                                {/* </Show> */}
                            </div>
                        }}</For>

                        <Show when={data.videoProfiles.length < MAX_NUMBER_OF_VIDEO_PROFILES}>
                            <div>
                                <HoverOnClick
                                    className={resolutionPanel}
                                    trigger={
                                        <a style={{ color: 'blue', 'font-size': '0.8em', cursor: 'pointer' }}
                                        >⊕ Video Profile</a>
                                    }>
                                    <For each={data.video_codec == 'hevc' ? pixels.all : pixels.all}>{(e) =>
                                        <div
                                            onClick={() => {
                                                console.log('>>> click')
                                                addNewVideoProfile(e[0], e[1], null, data)
                                            }}>
                                            {e[0]} × {e[1]}
                                        </div>}</For>
                                </HoverOnClick>
                            </div>
                        </Show>
                    </Group>

                </div>
                <div id="right" style={{ 'padding-top': '0px', position: 'relative' }}>
                    <Group name="CURL Command" state={false}
                        style={{ 'background-color': 'rgb(212 223 233)', 'min-width': '20vw' }}>
                        <div style={{ position: 'relative', width: '100%' }} class={code}>
                            <div style={{ position: 'absolute', right: 0, padding: '5px' }}>
                                <span
                                    class={copyToClipboard}
                                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                    onclick={async () => await navigator.clipboard.writeText(codeSnippet())}
                                    style={{ 'background-color': 'white', position: 'relative', border: '1px solid gray', 'border-radius': '5px', color: 'gray', padding: '3px', 'font-size': '0.7em', cursor: 'pointer' }}>
                                    <span class="ack" style={{
                                        position          : 'absolute',
                                        width             : 'max-content',
                                        top               : '50%',
                                        transform         : 'translateY(-50%)',
                                        'background-color': 'black',
                                        color             : 'white',
                                        'border-radius'   : '5px',
                                        padding           : '1px 5px',
                                        right             : '110%',
                                        // 'display'         : 'none',
                                        // 'transition'      : 'visibility 0s, opacity 0.5s linear',
                                    }}>Copied to clipboard!</span>
                                        copy
                                </span>
                            </div>
                            <Highlight language={Language.JAVASCRIPT} style={{ 'font-size': '0.75em', 'line-height': 1 }}>
                                {/* <pre
                                    style={{
                                        'background-color': '#fff',
                                        'border-radius'   : '0.5em',
                                        'font-size'       : '0.8em',
                                        'height'          : 'fit-content',
                                        'margin'          : '2px',
                                        'padding'         : '1em',
                                        'white-space'     : 'pre-wrap',
                                        'width'           : '100%',
                                        'word-wrap'       : 'break-word',
                                    }}
                                > */}
                                {codeSnippet()}
                                {/* </pre> */}
                            </Highlight>
                            {/* </Highlight> */}
                            {/* <pre class={code} ref={e => {
                                hljs.highlightElement(e)
                                // Prism.highlightElement(e)
                                // setTimeout(() => {
                                //     // hljs.highlightAll()
                                // }, 100)
                                // hljs.highlightElement(e)
                            }}
                            // innerHTML={coderSnippetColorized()}
                            >
                                <code>
                                    {codeSnippet()}
                                </code>
                            </pre> */}
                        </div>
                    </Group>
                    <Group name="Advanced" state={p.mainState.panelStates.advanced} style={{  }}>
                        <Row conditional path="" errors={errors} dict={data} label="Logo URL"  obj={simplifiedJobInput} key="logo_url"></Row>
                        <Row conditional path="" errors={errors} dict={data} label="Audio Volume (dB)" obj={simplifiedJobInput} key="audio_volume" step={0.1} coerceNumber={(n) => Number(n).toFixed(1) }></Row>
                        <Row conditional path="" errors={errors} dict={data} label="Create .tar File"  obj={simplifiedJobInput} key="create_tar_file"></Row>
                        <div style={{ 'font-weight': 600, 'padding-left': '0.25em', 'padding-top': '0.75em' }}>Video Coding Options</div>
                        <Row conditional path="" errors={errors} dict={data} label="Video Quality"  obj={simplifiedJobInput} key="h264_quality"></Row>
                        <Row conditional path="" errors={errors} dict={data} label="IP Distance"  obj={simplifiedJobInput} key="ip_distance" coerceNumber={(n) => Number(n).toFixed(0) }></Row>
                        <Row conditional path="" errors={errors} dict={data} label="GOP Length"  obj={simplifiedJobInput} key="gop_length" disabled={(data.video_codec == 'mpeg2' && data.scte35_passthough == true)}></Row>
                        <Row conditional path="" errors={errors} dict={data} label="Aspect Ratio and Rotation Options"  obj={simplifiedJobInput} key="video_aspect_ratio"
                            moreStyle={{ 'max-width': '350px' }}
                            valueMap={{ 'passthrough'                                  : 'Passthrough',
                                        'force_4:3'                                    : 'Force 4:3',
                                        'force_16:9'                                   : 'Force 16:9',
                                        'invert_aspect_ratio'                          : 'Invert Aspect Ratio',
                                        'rotate_counterclockwise'                      : 'Rotate Counterclockwise',
                                        'rotate_clockwise'                             : 'Rotate Clockwise',
                                        'rotate_counterclockwise + invert_aspect_ratio': 'Rotate Counterclockwise & Invert Aspect Ratio',
                                        'rotate_clockwise + invert_aspect_ratio'       : 'Rotate Clockwise & Invert Aspect Ratio'                        }}></Row>
                        <Row conditional path="" errors={errors} dict={data} label="Rotation Side Panel Transparency"  obj={simplifiedJobInput} key="rotation_blackness"></Row>
                        <Row conditional path="" errors={errors} dict={data} label="Closed Captions"  obj={simplifiedJobInput} key="closed_captions"></Row>
                        <Show when={data.output_container == 'ts'}>
                            <div style={{ 'font-weight': 600, 'padding-left': '0.25em', 'padding-top': '0.75em' }}>SCTE35</div>
                            <Row conditional path="" errors={errors} dict={data} label="Digital Ad Insertion (SCTE35 Passthough)"  obj={simplifiedJobInput} key="scte35_passthough"></Row>
                            <Row conditional path="" errors={errors} dict={data} label="SCTE35 PID Remap"  obj={simplifiedJobInput} key="scte35_pid_remap" disabled={data.scte35_passthough == false}></Row>
                        </Show>
                    </Group>
                </div>
            </div>
            <div>&nbsp;</div>
            <div
                style={{  position: 'sticky', bottom: '0.5em', 'z-index': 100 }}>
                <div style={{ bottom: 0, width: '100%', display: 'flex', 'flex-direction': 'column', 'align-items': 'flex-start' }}>
                    <Show when={(job() != null)}>
                        <Show
                            when={progress() != 100}>
                            <Show when={progressStatus() != null}>
                                <div style={{ margin: 'auto', 'background-color': 'white', padding: '0.25em', 'border-radius': '3px' }}>{progressStatus()}...</div>
                            </Show>
                            <progress  style={{ width: '100%' }} max="100" value={progress()}></progress>
                        </Show>
                        {/* <pre>{JSON.stringify(jobStatus())}</pre> */}
                    </Show>

                </div>
            </div>
            <Show when={errors()}>
                <div class="jobSubmitErrors" style={{ color: 'red' }}>
                    <span>Please fix the highlighted fields then try again. </span>
                    <Show when={p.mainState.collapsed == false}>
                        <span>Click <a href="#"style={{ color: 'blue' }} onClick={(e) => {
                            e.preventDefault()
                            p.mainState.collapsed = true
                            Object.values(p.mainState.panelStates).map(s => s[1](true))
                        }}>here</a> to unfold all panels.</span></Show>
                </div>
            </Show>
            <Show when={onRunErrors()}>
                <div style={{ color: 'red' }}>{onRunErrors() ?? ''}
                </div>
            </Show>
            <Button
                style={{ 'box-shadow'      : 'rgba(0, 0, 0, 0.2) 0 0px 8px 4px',
                         'background-color': (errors() == undefined ? undefined : '#999'),
                }}
                disabled={job() != undefined || p.mainState.isUploading == true}
                onClick={(e) => escapeOwner(async () => {
                    e.preventDefault()
                    const err1 = enforceConstraints(data)
                    const op = commonSchema.simplifiedJobInput.safeParse(data)
                    const err2 = op.success ? undefined : op.error

                    setOnRunErrors(undefined)
                    if(err1 == undefined && err2 == undefined && op.success) {
                        console.log('sending', data)
                        if((window as any).__dryRun == true) {
                            (op.data as any).__dryRun = true
                        }
                        const out = await client()!.jobs.mutate(op.data)
                        if(out != undefined) {
                            if('error' in out) {
                                console.log(out)
                                toast(((out as { message?: string })?.message) ?? out['error'], { icon: '❌' })
                            } else {
                                if(commonSchema.isJobLimitReached(out)){
                                    toast("You've reached your monthly job quota. Please subscribe or wait until next month to try again.", { icon: '❌' })
                                }
                                else if(commonSchema.isTimeLimitReached(out)){
                                    toast('Your trial period has expired. Please subscribe and try again.', { icon: '❌' })
                                } else if(commonSchema.isValidationError(out)) {
                                    setOnRunErrors(out.message)
                                } else {
                                // IS THIS DONE WITHOUT AN OWNER?
                                    setJobId({ id: out._auto_generated_id_, date: new Date() })
                                    toast('Your job is now transcoding', { icon: '✅' })
                                }
                            }
                        }
                        setErrors(undefined)
                    } else {
                        setErrors(unifyZodErrors(err1, err2))
                    }
                })}>{progressStatus() != undefined ? 'Running' : 'Run'} Job</Button>
        </Content>
    </main>
}



import { h4 } from 'app/src/constants'
import { AppURLs, escapeOwner, parse_picture_transform, simplifiedJobInput, SUPPORTED_CONTAINERS } from 'common'
import { getCodeSnippet } from 'common'
import { nanoid } from 'nanoid'
import toast from 'solid-toast'
import { style } from 'typestyle'
import { z, ZodType, ZodTypeDef } from 'zod'

import { borderRadius, foreground, padding } from '../../constants'
import { css } from '../../css'
import { addInvisibleBreaks, em } from '../../helpers'
import { header } from '../../style'
// import { getCodeSnippet } from '../common'
import { useAuthenticatedContext } from '../context'
import { Button, Content } from '../Library'
import { signalFromPrefix } from '../replicache'
import { Upload } from '../Upload'
import { HoverOnClick, Mouseover } from './Mouseover'


const Group = (p: { name: string, children: JSX.Element, state?: boolean | Signal<boolean>, hideChevron?: true, alwaysOn?: boolean, topRight?: JSX.Element, style?: JSX.CSSProperties }) => {
    const [state, setState] = typeof p.state == 'boolean' ? createSignal(p.state ?? false) : (typeof p.state === 'undefined' ? createSignal(false) : p.state)
    return <div class={group} style={p.style}>
        <Show when={p.topRight !== undefined}><span style={{ float: 'right' }}>{p.topRight}</span></Show>
        <h4 class={h4(0.5, state())}
            style={{ cursor: 'pointer', 'user-select': 'none' }}
            onClick={e => {e.preventDefault(); setState((p.hideChevron ?? false) || (p.alwaysOn == true) || !state())}}>{p.name}
            <span style={{ display: p.hideChevron == true || (p.alwaysOn == true) ? 'none' : 'inline' }}>{state() ?
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="12" width="12"><path d="M.541,5.627,11.666,18.2a.5.5,0,0,0,.749,0L23.541,5.627" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                : <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="12" width="12"><path d="M5.651,23.5,18.227,12.374a.5.5,0,0,0,0-.748L5.651.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            }</span></h4>
        <Show when={state()}>
            {p.children}
        </Show>
    </div>
}

type Format = 'ts' | 'mp4' | 'mp4+dash' | 'mp4+hls' | 'mp4+dash+hls'
type FormatMapCodomain = Pick<Data, 'segmented_output_dash' | 'segmented_output_hls' | 'output_container'>
type FormatMap = Record<Format, FormatMapCodomain>
const formatMap: FormatMap = {
    'ts'          : { output_container: 'ts', segmented_output_dash: false, segmented_output_hls: false },
    'mp4'         : { output_container: 'mp4', segmented_output_dash: false, segmented_output_hls: false },
    'mp4+dash'    : { output_container: 'mp4', segmented_output_dash: true, segmented_output_hls: false },
    'mp4+hls'     : { output_container: 'mp4', segmented_output_dash: false, segmented_output_hls: true },
    'mp4+dash+hls': { output_container: 'mp4', segmented_output_dash: true, segmented_output_hls: true },
}


const enforceConstraints = (data: Data): z.ZodError<Data> | undefined => {
    console.log('enforceConstraints', new Date())
    return enforceConstraintsNew(data, constraints, toast)
    /*
    const usingIgolgiStoreOutput = data.cloud_credentials.output.cloud_provider == 'igolgi-store'

    contingent(data, 'ts_mode_input_audio_source_stream_count', data.master_variant_mode == false && data.output_container == 'ts')
    contingent(data, 'audio_output_path', scenario(data) == 2) // CHECK THAT IT'S A FILE AND NOT A DIRECTORY
    contingent(data, 'output_bucket', scenario(data) == 3)
    contingent(data, 'output_subpath', scenario(data) == 3)
    // contingent(data, 'input', data.cloud_credentials.input.cloud_provider != 'file-upload')
    contingent(data, 'segment_length', scenario(data) == 3)
    console.log(scenario(data))
    for(const [i, _] of Object.entries(data.videoProfiles)) {
        contingent(data.videoProfiles[Number(i)], 'profile_name', scenario(data) == 3 && !usingIgolgiStoreOutput)
        contingent(data.videoProfiles[Number(i)], 'output', scenario(data) != 3 && !usingIgolgiStoreOutput)
        contingent(data.videoProfiles[Number(i)], 'audio_profiles', data.master_variant_mode == false && data.output_container == 'mp4', '')
    }
    for(const [i, _] of Object.entries(data.audioProfiles)) {
        contingent(data.audioProfiles[Number(i)], 'dolby_digital_dialnorm', data.audioProfiles[Number(i)].audio_codec == 'ac3')
    }
    if(data.output_container == 'ts') {
        data.master_variant_mode = false
    }
    if(data.output_container == 'mp4' && data.video_codec == 'h.264') {
        data.master_variant_mode = true
    }
    // If we have more than video profile at the output, `force_progressive` must be forced to True.
    if(data.videoProfiles.length > 1){
        data.force_progressive = true
    }
    if(data.video_codec == 'mpeg2' && data.scte35_passthough == true){
        data.gop_length = 1
    }
    //`keep_fps` is dependent on `force_progressive`.
    // Only makes sense to expose it if `force_progressive` is enabled.
    // If `force_progressive` is false then `keep_fps` should be true (but hidden).
    if(data.force_progressive == false) {
        data.keep_fps = true
    }
*/
}
const mergeErrors = <T extends any>(a: z.ZodError<T>, b: z.ZodError<T>): z.ZodError<T> => {
    const resp = new z.ZodError<T>(a.issues)
    for(const x of b.issues) {
        resp.addIssue(x)
    }
    return resp
}

const Format = (p: { data: Data }): JSX.Element => {
    const [format, setFormat] = createSignal<Format>('ts') // match based on data

    const update = () => {
        console.log('format update')
        if(p.data.output_container == 'ts') setFormat('ts')
        else if(p.data.segmented_output_dash == true && p.data.segmented_output_hls == true) setFormat('mp4+dash+hls')
        else if(p.data.segmented_output_dash == true && p.data.segmented_output_hls == false) setFormat('mp4+dash')
        else if(p.data.segmented_output_dash == false && p.data.segmented_output_hls == true) setFormat('mp4+hls')
        else if(p.data.segmented_output_dash == false && p.data.segmented_output_hls == false) setFormat('mp4')
    }
    update()
    createEffect(update)

    return <>
        <Inputs>
            <select value={format()}
                onInput={(e) => {
                    const value = e.currentTarget.value as Format
                    setFormat(value)
                    batch(() => {
                        for(const [k, v] of Object.entries(formatMap[value])) {
                            const kk = k as keyof FormatMapCodomain
                            // @ts-ignore
                            p.data[kk] = v
                        }
                        // enforceConstraints(p.data)
                    })
                    applyDefaults(p.data)
                }}>
                <option value='mp4'>MP4</option>
                <option value='ts'>TS</option>
                <option value='mp4+dash'>DASH</option>
                <option value='mp4+hls'>HLS</option>
                <option value='mp4+dash+hls'>DASH & HLS</option>
            </select>
        </Inputs>

        <Row errors={() => undefined}
            path=""
            dict={p.data}
            obj={simplifiedJobInput}
            disabled={!(p.data.output_container == 'mp4' && p.data.segmented_output_dash == false && p.data.segmented_output_hls == false)}
            // callbackOnInputType={() => enforceConstraints(p.data)}
            label="Separate Audio"
            key="separate_audio"></Row>
    </>
}

// function signalWithValidation<T>(initialValue: T, constraintFn: (old: T, updated: T) => T): [state: Accessor<T>, setState: (x: T) => void] {
//     // Create a basic signal
//     const [signal, setSignal] = createSignal<T>(initialValue)

//     // Return a getter and a custom setter that applies the constraints
//     return [
//         signal,
//         (value: T): void => {
//             // Apply the constraint function to the value before setting the signal
//             const constrainedValue = constraintFn(signal(), value);
//             (setSignal as (x: T) => void)(constrainedValue)
//         },
//     ]
// }


const container = (a: number): string => {
    const c = `@media(max-width: ${a}px)`
    return style({
        $nest: {
            'left': {
            },
            '#right div': {
                'maxWidth': '25vw',
            },
            [c]: {
                flexDirection: 'column',
                gap          : '0.5em',
                $nest        : {
                    '#right div': {
                        maxWidth: '100%',
                    },
                },
            },
        },
    })
}
const group = style({
    'backgroundColor': foreground,
    'marginBottom'   : '20px',
    // border           : '1px solid #eee',
    'borderRadius'   : borderRadius,
    padding          : padding,
    // transition       : 'all 1s',
    $nest            : {
        // '& + &': {
        //     'marginBottom': '1px',
        // },
        'svg': {
            overflow  : 'visible',
            marginLeft: em(0.5),
        },
        'svg path': {
            strokeWidth: '5px',
        },
        '.input:last-child .widget': {
            marginBottom: 0,
        },
    },
})



const topRightStyle = style({
    color   : '#a90000',
    fontSize: '0.8em',
    cursor  : 'pointer',
})

const templateButton = css((t) => ({
    // backgroundColor: '#5E7FA2',
    width   : '15em',
    padding : '1px',
    fontSize: '0.8rem',
}))
const templates = css((t) => ({
    display                  : 'block',
    // position                 : 'absolute',
    bottom                   : '0px',
    right                    : 0,
    'font-size'              : '1rem',
    width                    : 300,
    // float                          : 'right',
    '& .solid-select-control': {
        outline: '1px solid #ccc',
    },
    '& .solid-select-list': {
        'font-weight'  : '400',
        backgroundColor: 'white',
        'font-size'    : '1rem',
    },
}))

const Regions = (p: { cloudCreds: z.infer<typeof commonSchema.cloudCreds> }) => {
    const regions = createMemo(() => {
        if(p.cloudCreds.cloud_provider == 'aws') {
            if(p.cloudCreds.region == undefined || !commonSchema.awsRegions._def.options.map(x => x.value).includes(p.cloudCreds.region)) {
                p.cloudCreds.region = commonSchema.awsRegions._def.options[0].value as any
            }
            return commonSchema.awsRegions
        }else if(p.cloudCreds.cloud_provider == 'oracle') {
            if(p.cloudCreds.region == undefined || !commonSchema.ociRegions._def.options.map(x => x.value).includes(p.cloudCreds.region)) {
                p.cloudCreds.region = commonSchema.ociRegions._def.options[0].value as any
            }
            return commonSchema.ociRegions
            /*
        } else if(p.cloudCreds.cloud_provider == 'gcloud') {
            if(!commonSchema.googleRegions._def.options.map(x => x.value).includes(p.cloudCreds.region)) {
                p.cloudCreds.region = commonSchema.googleRegions._def.options[0].value as any
            }
            return commonSchema.googleRegions
            */
        } else return { _def: { options: [] } } as const
    })

    const description = commonSchema.simplifiedJobInput._def['shape']().cloud_credentials._def['shape']().input._def['shape']().cloud_provider._def.description
    return <Show
        when={p.cloudCreds.cloud_provider != 'gcloud'}>
        <Inputs label="Region" description={description}>
            <select
                value={p.cloudCreds.region!}
                onInput={(e) => {
                    console.log('region-Update', e.currentTarget.value)
                    p.cloudCreds.region = e.currentTarget.value as any
                }}
            >
                <For each={regions()._def.options as any[]}>{(e) => {
                    const val = (e as z.ZodLiteral<string>).value
                    return <option selected={p.cloudCreds.region == val} value={val}>Region: {val}</option>
                }}</For>
            </select></Inputs>
    </Show>
}

type CloudCredsParams = {
    where: 'input' | 'output',
    data: Data,
    getFiles: Accessor<[id: string, t: commonSchema.File][]>,
    middle?: JSX.Element,
    narrow?: commonSchema.CloudTag[],
    urls: AppURLs,
    errors: Accessor<z.ZodError<Data> | undefined>,
    fileDropdownSelectedIdSignal?: Signal<string | undefined>
}
const CloudCreds = (p: CloudCredsParams) => {
    const makeUrl = (s: string) => `${p.urls.base}/uploads/${s}`
    createEffect(() => {
        if(p.data.cloud_credentials[p.where].cloud_provider == 'file-upload') {
            const f = p.getFiles()
            if(f.length > 0) {
                p.data.input = makeUrl(p.fileDropdownSelectedIdSignal?.[0]() ??  f[0][1].id)
                // 'https://streamengine.igolgi.com/uploads/7a63bbb5503c001b0a85efd2eeb1243b
                // if(newURL.substring(0, 10) != (p.data.input ?? '').substring(0, 10)) {
                //     debugger
                //     p.data.input = newURL
                // }
            }
        }
    })

    const [sortUploadedFileOrder, setUploadedFileOrder] = createSignal<'az' | 'za'>('az')
    const UploadSortZA = () => <span style={{ width: '2em', 'padding-top': '0.2em' }} class={sortButton(sortUploadedFileOrder() == 'za')} onClick={() => setUploadedFileOrder('za')}><SortZA></SortZA></span>
    const UploadSortAZ = () => <span style={{ width: '2em',  'padding-top': '0.2em' }} class={sortButton(sortUploadedFileOrder() == 'az')}onClick={() => setUploadedFileOrder('az')}><SortAZ></SortAZ></span>

    return <>
        <Show when={p.where == 'output' && p.data.cloud_credentials[p.where].cloud_provider == p.data.cloud_credentials[p.where == 'output' ? 'input' : 'output'].cloud_provider && ['oracle', 'gcloud', 'aws'].indexOf(p.data.cloud_credentials[p.where].cloud_provider) > -1}>
            <a href="#" style={{ color: 'blue', 'text-decoration': 'none', 'font-size': '0.8em', 'margin-left': '6px' }}
                onClick={(e) => {
                    e.preventDefault()
                    const here = p.data.cloud_credentials[p.where]
                    const there = p.data.cloud_credentials[p.where == 'output' ? 'input' : 'output']
                    p.data.cloud_credentials[p.where] = { ...there }
                }}
                onMouseOver={() => {}}
            >Copy credentials from input</a>
        </Show>

        <Row
            rhs={p.where ==  'input'
                ? <Mouseover trigger={
                    <a href="#" style={{ color: 'blue', 'font-size': '0.75em', opacity: 0.75, 'text-decoration': 'none' }}>Supported Formats</a>
                }>
                    <div style={{ 'max-width': '30vw' }}>We support the following containers: <span>{SUPPORTED_CONTAINERS.reduce((acc, prev) => <>{acc} <pre style={{ display: 'inline' }}>{prev}</pre>, </>, <></>)}</span>.</div>
                </Mouseover>
                : null
            }
            path={`cloud_credentials.${p.where}`} errors={p.errors} dict={p.data.cloud_credentials[p.where]} obj={commonSchema.cloudCreds} label={p.where ==  'input' ? 'Cloud Provider or Remote Location' : 'Cloud Provider or Temporary Storage'} key="cloud_provider" narrow={p.narrow}></Row>
        {p.middle}

        <Show when={p.data.cloud_credentials[p.where].cloud_provider == 'file-upload'}>
            <div style={{ display: 'flex' }}>
                <select
                    value={p.fileDropdownSelectedIdSignal?.[0]()}
                    style={{ 'min-width': '100px', 'max-width': '300px', 'box-sizing': 'border-box'  }}
                    onchange={e => {
                        p.data.input = makeUrl(e.target.value)
                        console.error('1')
                        p.fileDropdownSelectedIdSignal?.[1](e.target.value)
                    }}>
                    <For each={p.getFiles().sort((a, b) => (sortUploadedFileOrder() == 'az' ? 1 : -1) * a[1].name.localeCompare(b[1].name))}>
                        {(f) => <option
                            selected={p.data.input == makeUrl(f[1].id)}
                            value={f[1].id}>{f[1].name}</option>}
                    </For>
                </select>
                <UploadSortAZ></UploadSortAZ><UploadSortZA></UploadSortZA>
            </div>
        </Show>
        <Show when={(['aws', 'gcloud', 'oracle'] as commonSchema.CloudTag[]).includes(p.data.cloud_credentials[p.where].cloud_provider)}>
            <Show when={(['aws', 'oracle'] as commonSchema.CloudTag[]).includes(p.data.cloud_credentials[p.where].cloud_provider)}>
                <Row path={`cloud_credentials.${p.where}`} errors={p.errors} isText={p.data.cloud_credentials[p.where].cloud_provider == 'oracle'} dict={p.data.cloud_credentials[p.where]} obj={commonSchema.cloudCreds} label="Access Key" key="access_key"></Row>
            </Show>
            <Row path={`cloud_credentials.${p.where}`} errors={p.errors} isText={p.data.cloud_credentials[p.where].cloud_provider == 'oracle'} dict={p.data.cloud_credentials[p.where]} obj={commonSchema.cloudCreds} label="Secret Key" key="secret_key"></Row>
            <Show when={p.data.cloud_credentials[p.where].cloud_provider == 'oracle'}>
                <Row path={`cloud_credentials.${p.where}`} errors={p.errors} dict={p.data.cloud_credentials[p.where]} obj={commonSchema.cloudCreds} label="Tenancy OCID" key="tenancy_ocid"></Row>
                <Row path={`cloud_credentials.${p.where}`} errors={p.errors} dict={p.data.cloud_credentials[p.where]} obj={commonSchema.cloudCreds} label="User OCID" key="user_ocid"></Row>
                <Row path={`cloud_credentials.${p.where}`} errors={p.errors} dict={p.data.cloud_credentials[p.where]} obj={commonSchema.cloudCreds} label="Fingerprint" key="oci_fingerprint"></Row>
            </Show>
            <Show when={p.data.cloud_credentials[p.where].cloud_provider == 'gcloud'}>
                <Row path={`cloud_credentials.${p.where}`} errors={p.errors} dict={p.data.cloud_credentials[p.where]} obj={commonSchema.cloudCreds} label="Project Id" key="project_id"></Row>
                <Row path={`cloud_credentials.${p.where}`} errors={p.errors} dict={p.data.cloud_credentials[p.where]} obj={commonSchema.cloudCreds} label="Client Email" key="client_email"></Row>
                <Row path={`cloud_credentials.${p.where}`} errors={p.errors} dict={p.data.cloud_credentials[p.where]} obj={commonSchema.cloudCreds} label="Client ID" key="client_id"></Row>
                <Row path={`cloud_credentials.${p.where}`} errors={p.errors} dict={p.data.cloud_credentials[p.where]} obj={commonSchema.cloudCreds} label="Private Key ID" key="private_key_id"></Row>
            </Show>
            <Regions cloudCreds={p.data.cloud_credentials[p.where]}/>
        </Show>
    </>
}

export const collapsed = style({
    $nest: {
        '.row': {
            // backgroundColor: 'red',
            // display: 'inline',
            $nest: {
                // 'input' : { width: '10%' },
                // 'select': { width: '10%' },
                // 'div'   : { display: 'inline' },
            },
            // transition: 'all 0.1s ease-out;',
        },
    },
})

const resolutionPanel = style({
    margin           : 'auto',
    width            : '10em',
    position         : 'absolute',
    // left             : '50%',
    // transform        : 'translate(-50%,0%)',
    bottom           : '1.5em',
    'backgroundColor': 'white',
    'borderRadius'   : '0.25em',
    border           : '1px solid #ccc',
    boxShadow        : '0px 5px 5px 3px rgba(0, 0, 0, 0.1)',
    '$nest'          : {
        'div': {
            padding  : '0.1em',
            textAlign: 'center',
            cursor   : 'pointer',
        },
        'div:hover': {
            backgroundColor: '#eee',
            cursor         : 'pointer',
        },
    },
})

const th = style({
    textAlign : 'left',
    fontSize  : '0.8em',
    fontWeight: 400,
})
const inlineRow = style({
    // backgroundColor: 'red',
    display: 'table-cell',
    $nest  : {
        'label': {
            display: 'none',
        },
        'input' : { width: '100%', paddingInline: '0.2em' },
        'select': { width: '100%', paddingInline: '0.2em' },
        // 'div'   : { display: 'inline' },
    },
})
const deleteTd = style({
    $nest: {
        'svg': {
            height     : '100%',
            overflow   : 'visible',
            // border     : '1px solid red',
            width      : '0.8em',
            marginRight: 0,
            opacity    : 0.5,
            transition : 'transform .2s',
            cursor     : 'pointer',
        },
        'svg path, svg line': {
            strokeWidth: 0,
            stroke     : 'black',

        },
        '&:hover': {
            $nest: {
                // must keep path and line separate because they don't distribute over ":hover"
                'svg path': {
                    stroke: 'black', // 227
                },
                'svg line': {
                    stroke: 'black', // 227
                },
                'svg': {
                    transform: 'scale(1.2)',
                    opacity  : 0.9,
                },
            },
            // backgroundColor: 'red',
            // backgroundColor: hsl(240, 100, 10), // #CA2F01
            color : 'red',
            cursor: 'pointer',
        },
    },
})



const _all_resolutions = [
    [416, 232],
    [640, 360],
    [768, 432],
    [960, 544],
    [1280, 720],
    [1920, 1080],
    [2560, 1440],
    [3840, 2160],
]

export const pixels = {
    all: _all_resolutions,
    // nonhd: _all_resolutions.filter(x => x[0] <= 1920 && x[1] <= 1080),
} as const


const removeExtraKeys = <T extends ZodType<any, ZodTypeDef>>(
    schema: T,
    obj: any,
): any => {
    if (typeof obj !== 'object' || obj === null) return obj

    if (schema instanceof z.ZodObject) {
        const shape = schema.shape
        const newObj: any = {}
        for (const key in shape) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                newObj[key] = removeExtraKeys(shape[key], obj[key])
            }
        }
        return newObj
    } else if (schema instanceof z.ZodUnion || schema instanceof z.ZodDiscriminatedUnion) {
        for (const option of schema.options) {
            if ((option as z.Schema).safeParse(obj).success == true) {
                return removeExtraKeys(option, obj)
            }
        }
    } else if (schema instanceof z.ZodIntersection) {
        return {
            ...removeExtraKeys(schema._def.left, obj),
            ...removeExtraKeys(schema._def.right, obj),
        }
    } else if (schema instanceof z.ZodArray) {
        if (Array.isArray(obj)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return obj.map((item) => removeExtraKeys(schema.element, item))
        }
    } else if (
        schema instanceof z.ZodNumber ||
    schema instanceof z.ZodString ||
    schema instanceof z.ZodBoolean ||
    schema instanceof z.ZodLiteral ||
    schema instanceof z.ZodEnum ||
    schema instanceof z.ZodNullable ||
    schema instanceof z.ZodOptional ||
    schema instanceof z.ZodAny ||
    schema instanceof z.ZodUnknown ||
    schema instanceof z.ZodVoid ||
    schema instanceof z.ZodNull
    ) {
        return obj
    }

    return obj
}


import question from './../../assets/question.svg'
import { ErrorHint } from './ErrorHint'
type Props<O extends z.ZodObject<any>, K extends keyof (O['shape'])> = {
    obj: O,
    children:JSX.Element,
    key: K
    style?: JSX.CSSProperties
}
export const Th = <O extends z.ZodObject<any>, K extends keyof (O['shape'])>(p: Props<O, K>): JSX.Element => {
    const description = p.obj._def.shape()[p.key].description
    return <th class={th} style={p.style}>
        {p.children}
        <Mouseover trigger={
            <img style={{ display: 'inline', 'padding-left': '5px', height: '1em', transform: 'translate(0px, 0.1em)' }} src={question}/>
        }>
            {description}
        </Mouseover>
    </th>
}


const sortButton = (on: boolean) => style({
    opacity  : on  ? 0.85 : 0.5,
    cursor   : 'pointer',
    // @ts-ignore
    '&:hover': {
        opacity: 1,
    },
})


const code = css({
    '& pre': {
        borderRadius: '0.5em',

    },
})()


const UploadWarning = (p: { uploadStatus:'warning' | 'suspended' | 'safe', quota: number }) => {
    const msg = (): string | undefined => {
        if(p.uploadStatus == 'warning') return `You are reaching your monthly uploads quota of ${p.quota}GB.`
        else if (p.uploadStatus == 'suspended') return `You have reached your monthly uploads quota of ${p.quota}GB.`
        return undefined
    }
    return <Show when={msg() != undefined}>
        <div style={{ 'background-color': p.uploadStatus == 'warning' ? 'orange' : 'red', color: 'white', padding: '0.5em' }}>{msg()}</div>
    </Show>
}
